import { createApp, defineAsyncComponent } from 'vue';
const App = defineAsyncComponent(() => import('@/App.vue'));
import router from '@/router';
import { store, key } from '@/store';

import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import '@vant/touch-emulator';
import { Lazyload, Dialog } from 'vant';

import i18n, { loadLocaleMessages } from '@/locales';
import '@/scripts/install';
import '@/assets/css/colors.scss';
import '@/assets/css/start.css';
import OrangeButton from '@/components/utils/OrangeButton.vue';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import VueVirtualScroller from 'vue-virtual-scroller';

const app = createApp(App);
app.use(i18n);
app.use(Lazyload, {
  lazyComponent: true,
  // attempt: 1,
});
app.use(Dialog);
app.component('OrangeButton', OrangeButton);
app.use(VueVirtualScroller);

const language = store.getters.languageShortName;
loadLocaleMessages(language).finally(() => {
  app.use(store, key).use(router).mount('#app');
});
