import { Module } from 'vuex';
import { State } from '@/store/state';
import { CATEGORY_BTN_TYPE } from '@/utils/consts';
import { NBGameNavigation } from '@/interface';

interface OrderState {
  sportOrderList: any;
}

interface Tab {
  title: string;
  path: string;
}

const sportTab: Tab = {
  title: 'betOrder.type.sports',
  path: '/betOrder/sports',
};
const lotteryTab: Tab = {
  title: 'betOrder.type.lottery_tab',
  path: '/betOrder/lottery',
};
const slotTab: Tab = {
  title: 'betOrder.type.casino',
  path: '/betOrder/casino',
};

const tabsMap = new Map<string, Tab>([
  [`${CATEGORY_BTN_TYPE.sport}`, sportTab], // 体育
  [`${CATEGORY_BTN_TYPE.lottery}`, lotteryTab], // 彩票
]);
const topBtns = [sportTab, lotteryTab, slotTab];

export const order: Module<OrderState, State> = {
  namespaced: true,
  state: {
    sportOrderList: null,
  },
  getters: {
    orderTabsSorted(state, getters, rootState, rootGetters) {
      // 排除 7:熱門, 8: 我的最愛
      const temps = (rootGetters.gameNavigation as NBGameNavigation[]).filter(
        (v) =>
          ~~v.id !== CATEGORY_BTN_TYPE.popular &&
          ~~v.id !== CATEGORY_BTN_TYPE.favorites
      );
      // 用于存放排序后的 tabs
      const sortedTabs: Tab[] = [];
      // 用于存放没有匹配到的 tabs
      const unmatchedTabs: Tab[] = [];
      // 遍历 tabs，根据数组一的顺序排序
      for (const value of temps) {
        const tab = tabsMap.get(value.id);
        if (tab && !sortedTabs.includes(tab)) {
          sortedTabs.push(tab);
        } else if (!tab && !sortedTabs.includes(slotTab)) {
          sortedTabs.push(slotTab);
        }
      }
      for (const tab of topBtns) {
        if (!sortedTabs.includes(tab)) {
          unmatchedTabs.push(tab);
        }
      }

      return sortedTabs.concat(unmatchedTabs);
    },
  },
  actions: {},
  mutations: {},
};
