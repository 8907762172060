import { Module } from 'vuex';
import { State } from '@/store/state';
import { NBBalanceItem, NBUserInfo, NBForgetPasswordConfig } from '@/interface';
import types from '@/store/mutation-types';
import { amountFormatStr, isNumericIncludeNegativeNumber } from '@/utils/util';
import { setUserId } from '@/scripts/tagEvent';

export interface UserState {
  nbUserInfo: NBUserInfo | null;
  balanceList: NBBalanceItem[];
  totalBalance: string;
  isLimit: string; // 彩金是否超过上限 1: 超过 0: 没超过
  forgetPasswordConfig: NBForgetPasswordConfig; // 忘记密码配置
}

export const user: Module<UserState, State> = {
  namespaced: true,
  state: {
    nbUserInfo: null,
    balanceList: [],
    totalBalance: '',
    isLimit: '',
    forgetPasswordConfig: {
      smsConfig: '0',
      smtpConfig: '0',
    },
  },
  getters: {
    userBalance(state, getters, rootState, rootGetters) {
      // const curBalance = state.balanceList.find(
      //   (v) => v.id === rootGetters.currencyId
      // );
      // return amountFormatStr(curBalance?.balance);
      //2024/12/27 总额后台直接返回，不需要再计算
      return amountFormatStr(state.totalBalance);
    },
    // 提现的地方要切货币，用这个获取方法
    specifiedBalance(state) {
      return (currencyId: string) => {
        const curBalance = state.balanceList.find((v) => v.id === currencyId);
        return amountFormatStr(curBalance?.balance);
      };
    },
    userAvatar(state, getters, rootState, rootGetters) {
      if (rootGetters.cdnUrl && state.nbUserInfo?.userPic) {
        return rootGetters.cdnUrl + state.nbUserInfo?.userPic;
      }

      return null;
    },
    userBirthday(state) {
      return isNumericIncludeNegativeNumber(state.nbUserInfo?.birthday)
        ? state.nbUserInfo?.birthday
        : '';
    },
    isAgent(state) {
      return (
        state.nbUserInfo?.isAgent === '1' &&
        state.nbUserInfo?.agentStatus === '1'
      );
    },
    userShowNickname(state) {
      return state.nbUserInfo?.userName ?? '';
    },
    hasRealname(state) {
      return (
        state.nbUserInfo?.realName && state.nbUserInfo?.realName.length > 0
      );
    },
    hasMobile(state) {
      return state.nbUserInfo?.mobile && state.nbUserInfo?.mobile.length > 0;
    },
    hasEmail(state) {
      return state.nbUserInfo?.email && state.nbUserInfo?.email.length > 0;
    },
    isSetFundPassword(state) {
      return state.nbUserInfo?.isSetFundPassword;
    },
    autoTransfer(state) {
      return state.nbUserInfo?.autoTransfer === '1';
    },
    hasVIP(state) {
      return state.nbUserInfo?.vipSwitch === '1';
    },
  },
  actions: {
    updateUserInfo({ commit, rootState }, info) {
      if (
        info?.userName &&
        (!rootState.user?.nbUserInfo ||
          info.userName != rootState.user.nbUserInfo.userName)
      ) {
        // 用户信息不存在 或者 新用户信息不等于旧用户信息
        setUserId(info.userName);
      }

      return new Promise((resolve) => {
        commit(types.UPDATE_USERINFO, { info, identity: rootState.userType });
        resolve(null);
      });
    },
    updateUserInfoAfterEdit({ commit }, info) {
      return new Promise((resolve) => {
        commit('updateUserInfoAfterEdit', info);
        resolve(null);
      });
    },
    actionsForgetPasswordConfigSet({ commit }, data) {
      commit('forgetPasswordConfigSet', data);
    },
  },
  mutations: {
    //保存个人信息
    [types.UPDATE_USERINFO](state, { info, identity }) {
      state.nbUserInfo = info;
      sessionStorage.setItem(
        'user',
        JSON.stringify({ userName: info.userName, identity })
      );
    },
    //余额列表
    [types.UPDATE_USER_BALANCE](state, payload) {
      state.balanceList = payload.list;
      //总余额 用于个人页面和转额页面展示
      state.totalBalance = payload.total;
      //彩金是否超过限额
      state.isLimit = payload.isLimit;
    },
    clearData(state) {
      state.balanceList = [];
      state.nbUserInfo = null;
    },
    updateUserInfoAfterEdit(state, info) {
      if (!state.nbUserInfo || !info) {
        return;
      }
      Object.keys(info).forEach((v) => {
        (state.nbUserInfo as any)[v] = info[v];
      });
    },
    forgetPasswordConfigSet(state, payload) {
      Object.keys(payload).forEach((key) => {
        state.forgetPasswordConfig[key as keyof NBForgetPasswordConfig] =
          payload[key].status;
      });
    },
  },
};
