import { InjectionKey } from 'vue';
import {
  createStore,
  useStore as baseUseStore,
  Store,
  createLogger,
} from 'vuex';
import { games } from '@/store/modules/games';
import { user } from '@/store/modules/user';
import { vips } from '@/store/modules/vips';
import { activities } from '@/store/modules/activities';
import { messages } from '@/store/modules/messages';
import { withdraw } from '@/store/modules/withdraw';
import { recharge } from '@/store/modules/recharge';
import { messagePopupBox } from '@/store/modules/messagePopupBox';
import { redpack } from '@/store/modules/redpack';
import { rebates } from '@/store/modules/rebates';
import { order } from '@/store/modules/order';
import createPersistedState from 'vuex-persistedstate';
import loginLogic from '@/scripts/loginLogic';
import mainState, { State } from '@/store/state';
import mutation from '@/store/mutation';
import _cloneDeep from 'lodash/cloneDeep';
import _forEach from 'lodash/forEach';
import _set from 'lodash/set';
import PKG from '../../package.json';
import localStorageFilter from '@/store/localStorageFilter';
import getters from '@/store/getters';
import customStore from '@/request/http/store';
import { agent } from '@/store/modules/agent';
import { isInWebview, isWebApp } from '@/utils/util';
import hallLogic from '@/scripts/hallLogic';
import { requestGameNavigationList } from '@/scripts/gameLogic';
import { setUserId } from '@/scripts/tagEvent';
import { showToast } from 'vant';
import i18n from '@/locales';
import { showNBGuestLimitDialog } from '@/utils/dialog';
import { chatRoom } from '@/store/modules/chatRoom';

const release = process.env.VUE_APP_ENV === 'production';

const versionKey = `${PKG.name}_version`;
const localVersion = localStorage.getItem(versionKey);

const statePlugin = createPersistedState({
  key: `${PKG.name}_main`,
  setState(key, state, storage) {
    const _state = _cloneDeep(state);
    _forEach(localStorageFilter, (value, itemKey) => {
      _set(_state, itemKey, value);
    });
    if (localVersion !== PKG.version) {
      localStorage.removeItem(PKG.name);
      localStorage.setItem(versionKey, PKG.version);
      // _set(_state, 'user.captchaToken', undefined);
    }

    return storage.setItem(key, JSON.stringify(_state));
  },
});

export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  state: mainState,
  getters: {
    ...getters,
    loginSuccess(state) {
      return state.token != null && state.token != '';
    },
    shouldShowDownload(state) {
      return state.showDownloadBar && !isWebApp() && !isInWebview();
    },
  },
  mutations: {
    ...mutation,
    //登出清除信息
    logout(state) {
      state.userType = '';
    },
    changeMenuState(state) {
      state.menuOpened = !state.menuOpened;
    },
    changeServiceShow(state, payload) {
      state.serviceShow = payload?.show;
    },
    //显示/隐藏 砍一刀悬浮窗
    showSpinSidebar(state, status: boolean) {
      state.showSpinSidebar = status;
    },
    //保存静态广告数据
    saveStaticBanner(state, payload) {
      state.staticBanner = payload.list;
    },
    //显示/隐藏 砍一刀悬浮窗
    showDownloadBar(state, status: boolean) {
      state.showDownloadBar = status;
    },
    updateChromiumReadyPwa(state) {
      state.chromiumReadyPwa = true;
      state.showPwa = true;
    },
  },
  actions: {
    logoutAction({ commit }) {
      setUserId(null); // 清除gtag配置的userid
      customStore.set('identity', null);
      customStore.set('token', null);
      hallLogic.getSiteConfig();
      requestGameNavigationList();
      return new Promise((resolve) => {
        commit('agent/reset');
        commit('messagePopupBox/clearData');
        commit('messages/clearData');
        commit('rebates/clearData');
        commit('recharge/clearData');
        commit('user/clearData');
        commit('vips/clearData');
        commit('redpack/reset');
        commit('logout');
        loginLogic.thirdSignOut();
        resolve(null);
      });
    },
    shouldShowRedeem({ getters }) {
      if (!getters.loginSuccess) {
        showToast(i18n.global.t('redeemCode.NOT_LOGIN_TOAST'));
        return false;
      }
      if (getters.userType.isGuest) {
        showNBGuestLimitDialog();
        return false;
      }

      return true;
    },
  },
  modules: {
    games,
    user,
    vips,
    activities,
    messages,
    messagePopupBox,
    redpack,
    agent,
    withdraw,
    rebates,
    recharge,
    order,
    chatRoom,
  },
  strict: !release,
  plugins: release ? [statePlugin] : [createLogger(), statePlugin],
});

export function useStore() {
  return baseUseStore(key);
}
