export enum NBOcType {
  Google = '1',
  Facebook = '2',
}

export enum NBWithdrawType {
  BANK = 1,
  PIX = 2,
  USDT = 3,
  CG = 5,
}

export enum NBCaptchaType {
  LETTER_DIGIT = '1',
  SLIDER = '2',
}

export enum NBGuidePopupTime {
  HOME_EVERY_LOAD = '1',
  RECHARGE_FIRST = '2',
  WITHDRAW_FIRST = '3',
}
