import { Module } from 'vuex';
import { State } from '@/store/state';
import { ChatRoomInfo } from '@/interface';
import types from '@/store/mutation-types';

export interface ChatRoomState {
  roomInfo: ChatRoomInfo | null;
  socketUrl: string | null;
}

export const chatRoom: Module<ChatRoomState, State> = {
  namespaced: true,
  state: {
    roomInfo: null,
    socketUrl: null,
  },
  getters: {
    roomInfo: (state) => state.roomInfo,
    socketUrl: (state) => state.socketUrl,
  },
  mutations: {
    [types.CHATROOM_INFO_UPDATE](state, payload: ChatRoomInfo) {
      state.roomInfo = payload;
    },
    [types.CHATROOM_SOCKET_URL_UPDATE](state, payload: string) {
      state.socketUrl = payload;
    },
  },
  actions: {
    updateRoomInfo({ commit }, payload: ChatRoomInfo) {
      commit(types.CHATROOM_INFO_UPDATE, payload);
    },
    updateSocketUrl({ commit }, payload: string) {
      commit(types.CHATROOM_SOCKET_URL_UPDATE, payload);
    },
  },
};
