import { Module } from 'vuex';
import { State } from '@/store/state';
import { NbListConfig } from '@/interface';
import types from '@/store/mutation-types';
import i18n from '@/locales';

export interface MessagesState {
  messages: number;
  notice: number;
  msgList: any[] | null;
  msgListConfig: NbListConfig | null;
  descriptionTxt: string;
}

const DefaultConfig = {
  page: 1,
  loading: false,
  finished: true,
  customEmpty: true,
  finishedText: '',
};

export const messages: Module<MessagesState, State> = {
  namespaced: true,
  state: {
    messages: 0,
    notice: 0,
    msgList: null,
    msgListConfig: null,
    descriptionTxt: '',
  },
  getters: {
    hasNotReadMessages(state) {
      const { messages, notice } = state;
      return messages + notice > 0;
    },
    hasNotReadMessagesCount(state) {
      const { messages, notice } = state;
      return {
        messages:
          messages > 99 ? '99+' : messages > 0 ? messages.toString() : null,
        notice: notice > 99 ? '99+' : notice > 0 ? notice.toString() : null,
        count: messages + notice > 99 ? '99+' : messages + notice,
      };
    },
    msgList(state) {
      return state.msgList;
    },
    msgListConfig(state) {
      return (
        state.msgListConfig ?? {
          ...DefaultConfig,
          finishedText: i18n.global.t('userMessage.LIST_LOAD_ALL'),
        }
      );
    },
  },
  actions: {},
  mutations: {
    clearData(state) {
      state.messages = 0;
      state.notice = 0;
      state.msgList = null;
      state.msgListConfig = null;
    },
    saveNoReadNumber(state, payload) {
      state.messages = payload?.messages;
      state.notice = payload?.notice;
    },
    [types.MESSAGE_LIST_UPDATE](state, payload) {
      if (!state.msgList) {
        state.msgList = [];
      }
      if (payload.type === 'clear') {
        state.msgList = [];
      } else if (payload.type === 'add') {
        state.msgList.push(...payload.data);
      }
    },
    [types.MESSAGE_LIST_CONFIG_UPDATE](state, payload) {
      if (!state.msgListConfig) {
        state.msgListConfig = {
          ...DefaultConfig,
          finishedText: i18n.global.t('userMessage.LIST_LOAD_ALL'),
        };
      }

      state.msgListConfig = {
        ...state.msgListConfig,
        ...payload,
      };
    },
    [types.MESSAGE_LIST_RESET](state) {
      state.msgList = null;
      state.msgListConfig = null;
    },
    [types.MESSAGE_LIST_SET_READ](state, messageId) {
      const res = state.msgList?.find((v) => v.messageListId === messageId);
      if (res) {
        res.isRead = '1';
      }
    },
    saveDescriptionTxt(state, payload) {
      state.descriptionTxt = payload;
    },
  },
};
