import { showToast } from 'vant';
import { removeExceptDigit } from '@/utils/consts';
import appInfo from '@/utils/appInfo';
import FingerprintJS, { Agent, GetResult } from '@fingerprintjs/fingerprintjs';
import { store } from '@/store';
import i18n from '@/locales';
import { useRegisterPush } from '@/scripts/bpush/bPushLogic';
import { isIOS } from 'vant/lib/utils';
import { showNbDialog } from './dialog';

const getWebDevice = async () => {
  const fp: Agent = await FingerprintJS.load();
  const result: GetResult = await fp.get();
  appInfo.deviceId = result.visitorId;
};

export const getDeviceId = async (): Promise<string | null> => {
  if (appInfo.deviceId == null) {
    await getWebDevice();
  }

  console.warn('device::', appInfo.deviceId);
  return Promise.resolve(appInfo.deviceId);
};

export const getDeviceVersion = (): string => {
  return navigator.userAgent;
};

export const uuid = (): string => {
  const date = Date.now();
  const txt = '1234567890'; //生成的随机机器码
  const len = 13; //机器码有多少位
  let pwd = ''; //定义空变量用来接收机器码
  for (let i = 0; i < len; i++) {
    pwd += txt.charAt(Math.floor(Math.random() * txt.length)); //循环机器码位数随机填充
  }

  return date + '-' + pwd;
};

const cover = (value: number) => {
  return value >= 10 ? value.toString() : '0' + value;
};

export const dateFormat = (timestamp: number | Date, type?: string) => {
  const date = new Date(timestamp);
  const format = {
    yy: cover(date.getFullYear()),
    MM: cover(date.getMonth() + 1),
    dd: cover(date.getDate()),
    hh: cover(date.getHours()),
    mm: cover(date.getMinutes()),
    ss: cover(date.getSeconds()),
  };

  if (type == null || type == undefined) {
    type = 'yy-MM-dd hh:mm:ss';
  }

  Object.entries(format).forEach(([k, v]) => {
    if (type) {
      if (type.indexOf(k) != -1) {
        type = type.replace(k, v);
      }
    }
  });

  return type;
};

// 将日期转换为时间戳
export const getDateFromString = (str: string) => {
  const temp = str.replace(/-/g, '/');
  return Date.parse(temp);
};
export const timestamp10 = () => {
  return Math.floor(Date.now() / 1000);
};
export const dateRange = (type?: number) => {
  const format = (n: number) => {
    return parseInt((n / 1000).toFixed(0));
  };
  const startTimestamp = (now: Date, days: number) => {
    // let milliseconds = new Date().getTime()
    // milliseconds = milliseconds - days * 24 * 3600 * 1000
    // const date = new Date(milliseconds)
    const date = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - days
    );
    // console.log(date);
    return date.getTime();
  };
  const endTimesTamp = (now: Date, days: number) => {
    if (days == 1) {
      //days等于1表示昨天但不包括今天，其余都包括今天
      const date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      return date.getTime();
    } else {
      return now.getTime();
    }
  };

  let start;
  let end;

  if (type != null && type != undefined && type >= 0) {
    const now = new Date();
    start = format(startTimestamp(now, type));
    end = format(endTimesTamp(now, type));
  }

  return [start, end];
};

export const amountFormatStr = (amount: string | null | undefined) => {
  if (isNumeric(amount)) {
    return amountFormat(amount);
  } else {
    return amount ?? '0';
  }
};

export const amountFormat = (amount: string | null | undefined) => {
  const am = amount || '0';
  const temp = String(am); // am.toFixed(digits);
  let prefix = temp.split('.')[0];
  const suffix = temp.split('.')[1];
  let result = '';
  while (prefix.length > 3) {
    result = `,${prefix.substring(prefix.length - 3, prefix.length)}` + result;
    prefix = prefix.substring(0, prefix.length - 3);
  }

  return `${prefix}${result}${suffix ? '.' + suffix : ''}`;
};

export const toFixedNoRound = (num: number, precision: number) => {
  const factor = Math.pow(10, precision);
  return Math.floor(num * factor) / factor;
};

export const webCopy = async (txt: string, tip: string | null) => {
  try {
    await navigator.clipboard.writeText(txt);
    showToast(tip || i18n.global.t('COPY_SUCCEED_TEXT'));
  } catch (error) {
    console.log('Unable to use navigator.clipboard:', error);
    fallbackCopyTextToClipboard(txt, tip);
  }
};

/**
 * 當瀏覽器不支持 `navigator.clipboard.writeText` 時的回退方法，建立一個 textarea 並複製文字到剪貼簿
 */
const fallbackCopyTextToClipboard = (txt: string, tip: string | null) => {
  const textarea = document.createElement('textarea');
  textarea.value = txt;
  textarea.setAttribute('contenteditable', 'true');
  textarea.style.position = 'absolute';
  textarea.style.left = '-9999px';
  document.body.appendChild(textarea);
  if (isIOS()) {
    const range = document.createRange();
    range.selectNodeContents(textarea);
    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);
    textarea.setSelectionRange(0, 9999);
  } else {
    textarea.select();
  }
  try {
    const copySucceed = document.execCommand('copy');
    if (!copySucceed) throw new Error(''); // 如果复制失败，抛出异常
    showToast(tip || i18n.global.t('COPY_SUCCEED_TEXT'));
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }
  document.body.removeChild(textarea);
};

export const goSupport = () => {
  if (store.getters.customerCenterOpen) {
    // 后台<显示客服中心>开启，弹出弹窗
    store.commit('changeServiceShow', { show: true });
    return;
  }
  if (store.getters.customerOnlineOpen) {
    // 后台<显示在线客服>开启，且存在在线客服，则外部浏览器打开第一个
    const first = store.state.customerConfig?.customerService?.find(
      (v) => v.type === '1'
    );
    if (first) {
      openUrl(first.url);
      return;
    }
  }

  const first = store.state.customerConfig?.customerService?.[0];
  if (first && first.browser) {
    openUrl(first.browser);
    return;
  }

  showToast(i18n.global.t('CONTACT_US_ERROR'));
};

export const openUrl = (url?: string) => {
  if (!url) return;

  const link = document.createElement('a');
  link.target = '_blank';
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/** 号码处理
 * @param no 需要处理的号码
 * @param startIndex 隐藏开始的索引
 * @param endIndex 隐藏结束的索引
 */
export const numberFormat = (
  no: number,
  startIndex: number,
  endIndex: number
) => {
  if (!no) return no;
  const str = `${no}`;
  let start = 0;
  let end = 0;
  if (startIndex && startIndex >= 0) start = startIndex;
  if (endIndex && endIndex > 0) end = endIndex;
  if (end == 0) return no;
  return (
    str.slice(0, start) +
    '*'.repeat(endIndex - startIndex) +
    str.slice(end, str.length)
  );
};

export const formatterPix = (input: string) => {
  const t = input.replaceAll(removeExceptDigit, '');
  let res = '';
  if (t.length > 0) {
    res += t.slice(0, 3);
  }
  if (t.length > 3) {
    res += '.' + t.slice(3, 6);
  }
  if (t.length > 6) {
    res += '.' + t.slice(6, 9);
  }
  if (t.length > 9) {
    res += '-' + t.slice(9, 11);
  }

  return res;
};

export const formatterAmountInput = (amount: string, decimal: number) => {
  let value = amount;
  if (decimal === 0) {
    // 如果decimal == 0，只能输入整数
    value = amount.replace(/[^\d]/g, '');
    if (value.startsWith('0')) {
      value = value.replace(/^0+/, '');
    }
  } else {
    // 只允许输入数字和小数点
    value = amount.replace(/[^\d.]/g, '');
    // 确保只有一个小数点
    value = value.replace(/\.{2,}/g, '.'); // 防止输入多个小数点
    // 只保留n位小数
    const regex = new RegExp(`^(-)*(\\d+)\\.(\\d{0,${decimal}}).*$`);
    value = value.replace(regex, '$1$2.$3');
    // 处理前置 '0' 的逻辑
    if (value.startsWith('0') && value.length > 1 && value[1] !== '.') {
      // 如果第二个字符是 '0' 或更大的数字，保留一个 '0'
      if (value[1] !== '0') {
        value = value.substring(1);
      } else {
        value = '0';
      }
    }
    // 如果输入的是 ".123"，转换为 "0.123"
    if (value.indexOf('.') === 0) {
      value = '0' + value;
    }
  }

  return value;
};

export const isWebApp = () => {
  const mqStandAlone = '(display-mode: standalone)';
  const isStandalone = window.matchMedia(mqStandAlone).matches;
  const isIOSStandalone = (window.navigator as any).standalone === true;
  return isStandalone || isIOSStandalone;
};

// 檢查是否已在webview下
export const isInWebview = () => {
  const useragent = window.navigator.userAgent;
  const rules = [
    'WebView',
    '(iPhone|iPod|iPad)(?!.*Safari/)',
    'Android.*(wv|Version/d+.*/d+.0.0.0 Mobile)',
  ];
  const regex = new RegExp(`(${rules.join('|')})`, 'ig');
  return Boolean(useragent.match(regex));
};

// const getFbpAndFbc = () => {
//   const fbp = cacheManager.getCookies("_fbp").replace("fb.0.", "fb.1.");
//   const fbc = cacheManager.getCookies("_fbc").replace("fb.0.", "fb.1.");
//   return {
//     fbp,
//     fbc,
//   };
// };

export const isNumeric = (val: any) =>
  typeof val === 'number' ||
  (typeof val === 'string' && /^\d+(\.\d+)?$/.test(val));
export const isNumericIncludeNegativeNumber = (val: any) =>
  typeof val === 'number' ||
  (typeof val === 'string' && /^-?\d+(\.\d+)?$/.test(val));

export const transferImageToBase64 = (url: string) => {
  return new Promise<string>((resolve) => {
    const image = new Image();
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
    image.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      const context = canvas.getContext('2d');
      if (!context) return;

      context.drawImage(image, 0, 0, image.width, image.height);
      const base64 = canvas.toDataURL('image/png');
      resolve(base64);
    };
  });
};

export const requestPermission = async () => {
  if (isInWebview()) {
    console.log('Notification use Native');
    return;
  }
  if ('Notification' in window) {
    if (Notification.permission === 'default') {
      if (isIOS()) {
        showNbDialog({
          title: i18n.global.t('dialog.PUSH.TITLE'),
          message: i18n.global.t('dialog.PUSH.MESSAGE'),
        }).then(doPermission);
      } else {
        doPermission();
      }
    } else if (Notification.permission === 'granted') {
      useRegisterPush();
    } else if (Notification.permission === 'denied') {
      // 被拒绝过
    }
  } else {
    console.error('Notification Not Found');
  }
};

const doPermission = () => {
  try {
    Notification.requestPermission().then((result) => {
      console.log('Notification', result);
      if (result === 'granted') {
        useRegisterPush();
      } else if (result === 'denied') {
        // 拒绝
      }
    });
  } catch (error) {
    console.error('[Notification Permission Error]', error);
  }
};

export const isMobile = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  // 移动端设备的正则表达式
  const mobileRegex = /android|iphone|ipod|ipad|blackberry|windows phone/i;
  // 判断是否匹配
  return mobileRegex.test(userAgent);
};

// 安全的 JSON 解析
export const getSafeJson = <T>(json: string): T | null => {
  try {
    return JSON.parse(json) as T;
  } catch (e) {
    return null;
  }
};
