import { useCustomRouter } from '@/router/custRouter';
import router from '@/router';

export const ROUTE_VIP = { path: '/vip', name: 'Vip' };
export const ROUTE_SHOP = { path: '/shop', name: 'Shop' };
export const ROUTE_CASH = { path: '/detailRecord', name: 'CashAccount' };
export const ROUTE_LOGIN = { path: '/login', name: 'Login' };
export const ROUTE_REGISTER = { path: '/register', name: 'Register' };
export const ROUTE_REGISTER_THIRD = {
  path: '/registerOther',
  name: 'RegisterOther',
};
export const ROUTE_SCORERULE = { path: '/scoreRule', name: 'ScoreRule' };
export const ROUTE_TASK_DRAW_RECORD = {
  path: '/bonusDrawRecord',
  name: 'BonusDrawRecord',
};
export const ROUTE_REBATE_RECORD = {
  path: '/rebateRecord',
  name: 'RebateRecord',
};
export const ROUTE_REBATE_RATIO = {
  path: '/rebateRatio',
  name: 'RebateRatio',
};
export const ROUTE_USER_INFO = {
  path: '/my/info',
  name: 'UserInfo',
};
export const ROUTE_USER_INFO_EDIT = {
  path: '/my/info/edit',
  name: 'UserInfoEdit',
};
export const ROUTE_CHAT_ROOM = {
  path: '/chatRoom',
  name: 'ChatRoom',
};
export const ROUTE_CHAT_ROOM_SETTING = {
  path: '/chatRoomSetting',
  name: 'ChatRoomSetting',
};

export const pushToPath = (path: string, query: any) => {
  useCustomRouter(null).push({ path, query });
};

export const replaceToPath = (path: string, query: any) => {
  useCustomRouter(null).replace({ path, query });
};

export const pushToLoginWithRedirect = (query: any) => {
  const path = router.currentRoute.value.path;
  pushToPath(ROUTE_LOGIN.path, { redirect: path, ...query });
};
// 登录后需要返回当前页
export const clickToLoginWithBack = (query: any) => {
  pushToPath(ROUTE_LOGIN.path, { redirect: 'back', ...query });
};

export const pushToRegieterWithRedirect = (query: any) => {
  const path = router.currentRoute.value.path;
  if (path == ROUTE_LOGIN.path) {
    // 从登录页跳转
    replaceToPath(ROUTE_REGISTER.path, query);
  } else {
    // 从非登录页跳转
    pushToPath(ROUTE_REGISTER.path, { redirect: path, ...query });
  }
};
// 注册后需要返回当前页
export const clickToRegisterWithBack = (query: any) => {
  pushToPath(ROUTE_REGISTER.path, { redirect: 'back', ...query });
};
export const pushToThirdRegister = (query: any) => {
  const path = router.currentRoute.value.path;
  if (path == ROUTE_LOGIN.path || path == ROUTE_REGISTER.path) {
    replaceToPath(ROUTE_REGISTER_THIRD.path, {
      from: router.currentRoute.value.name,
      ...query,
    });
  }
};
