import appInfo from '@/utils/appInfo';
import myWindow from '@/utils/myWindow';
import { version } from '../../package.json';
import { store } from '@/store';

const GA_MEASUREMENT_ID = 'G-XCGY2LLHW8';

export const setUserId = (userId: string | null) => {
  console.warn('tagEvent:: user_id', userId);
  if (typeof myWindow.gtag === 'function') {
    myWindow.gtag('config', GA_MEASUREMENT_ID, {
      user_id: userId,
    });
  } else {
    console.warn('tagEvent:: gtag is not found');
  }
};

const baseParams = () => {
  let userStatus = 'Non_Logged_in';
  if (store.getters.loginSuccess) {
    userStatus = store.getters.userType.isGuest ? 'Guest' : 'Logged_in';
  }
  return {
    Platform: appInfo.gtagPlatform,
    NB_version: version,
    User_status: userStatus,
  };
};

export const sendGoogleTag = (
  event: string,
  value: any,
  Action_type?: string
) => {
  if (!value) return;

  try {
    console.warn(
      'tagEvent:: ',
      event,
      JSON.stringify({ ...value, Action_type, ...baseParams() }, null, 4)
    );
    if (typeof myWindow.gtag === 'function') {
      myWindow.gtag('event', event, { ...value, Action_type, ...baseParams() });
    } else {
      console.warn('tagEvent:: gtag is not found');
    }
  } catch {
    //
  }
};

export const sendLoadFinishTag = () => {
  const timing =
    performance.timing || performance.getEntriesByType('navigation')[0];
  if (timing) {
    const loadTime = Number(
      (timing.loadEventEnd - timing.fetchStart) / 1000
    ).toFixed(2);
    if (+loadTime > 0) {
      sendGoogleTag(
        'html_finish',
        {
          Page_Load_Time: loadTime,
        },
        'view'
      );
    }
  }
};

export const sendHomePageFinishTag = () => {
  const timing = performance.timing;
  if (!timing) {
    return;
  }

  const nowTime = Date.now();
  const loadTime = Number(((nowTime - timing.loadEventEnd) / 1000).toFixed(2));
  if (loadTime > 0) {
    sendGoogleTag(
      'page_finish',
      {
        Page_Finish_Time: loadTime,
      },
      'view'
    );
  }
};

export const sendClickGoogleTag = (event: string, value: any) => {
  sendGoogleTag(event, value, 'click');
};

export const sendErrorGoogleTag = (value: any) => {
  sendGoogleTag('Error_Event', value, 'submit');
};

export const sendGoalsGoogleTag = (event: string, value: any) => {
  sendGoogleTag(event, value, 'submit');
};

export const sendViewGoogleTag = (event: string, value: any) => {
  sendGoogleTag(event, value, 'view');
};

export const Home_Banner_Click = 'Home_Banner_Click';
export const Home_Banner_Click_Position = {
  Banner: { banner: 'Banner', event_label: 'Click Banner' },
  LeftAD: { banner: 'LeftAD', event_label: 'Click Left AD' },
  MiddleAD: { banner: 'MiddleAD', event_label: 'Click Middle AD' },
  RightAD: { banner: 'RightAD', event_label: 'Click Right AD' },
};

export const Home_Message_Click = 'Home_Message_Click';
export const Home_Message_Click_Position = {
  Marquee: { Message: 'Marquee', event_label: 'Click Marquee' },
  Messages: { Message: 'Messages', event_label: 'Click Message' },
};

export const Home_GameMenu_Click = 'Home_GameMenu_Click';
export const Home_GameMenu_Click_Position: Record<
  number,
  Record<string, string>
> = {
  8: { game_menu: 'Favorite', event_label: 'Click Favorite Game Button' },
  1: { game_menu: 'Slot', event_label: 'Click Slot Game Button' },
  2: { game_menu: 'Lottery', event_label: 'Click Lottery Game Button' },
  3: { game_menu: 'Live', event_label: 'Click Live Game Button' },
  4: { game_menu: 'Sports', event_label: 'Click Sports Game Button' },
  5: { game_menu: 'Cards', event_label: 'Click Cards Game Button' },
  6: { game_menu: 'Fishing', event_label: 'Click Fishing Game Button' },
};

export const Home_GamesArea_Click = 'Home_GamesArea_Click';
export const Home_GamesArea_Click_Position = {
  Popular: {
    game_area: 'Popular',
    event_label: 'Click All Popular Game Button',
  },
  Sport: { game_area: 'Sport', event_label: 'Click All Sport Game Button' },
  Lottery: {
    game_area: 'Lottery',
    event_label: 'Click All Lottery Game Button',
  },
  EnterTheGame: { game_area: 'EnterTheGame', event_label: 'Enter The Game' },
  AddFavorite: {
    game_area: 'AddFavorite',
    event_label: 'Add Game To Favorites',
  },
  Third: (name?: string) => {
    return { game_area: name, event_label: `Click All ${name} Game Button` };
  },
};

export const Home_GamesLogo_Click = 'Home_GamesLogo_Click';
export const Home_GamesLogo_Click_Position = {
  Third: (name: string) => {
    return { game_platform: name, event_label: `Click ${name} Logo Button` };
  },
};

export const Home_Header_Click = 'Home_Header_Click';
export const Home_Header_Click_Position = {
  Login: { header_button: 'Login', event_label: 'Click Header Login' },
  Register: { header_button: 'Register', event_label: 'Click Header Register' },
  Deposit: { header_button: 'Deposit', event_label: 'Click Header Deposit' },
  RefreshBalance: {
    header_button: 'RefreshBalance',
    event_label: 'Click Header Refresh Balance Button',
  },
  Sidebar: { header_button: 'Sidebar', event_label: 'Click Header Sidebar' },
  DownloadApp: { header_button: 'DownloadApp', event_label: 'Download App' },
};

export const Home_Footer_Click = 'Home_Footer_Click';
export const Home_Footer_Click_Position = {
  Top: { footer: 'Top', event_label: 'Click Top Button' },
  SharePlatform: (name: string) => {
    return { footer: name, event_label: `Click ${name} Button` };
  },
};

export const Home_Floating_Click = 'Home_Floating_Click';
export const Home_Floating_Click_Position = {
  Wheel: {
    floating_button: 'Wheel',
    event_label: 'Click Wheel Floating Button',
  },
  RedPacket: {
    floating_button: 'RedPacket',
    event_label: 'Click Red Packet Floating Button',
  },
  Service: {
    floating_button: 'Service',
    event_label: 'Click Service Floating Button',
  },
};

export const Home_Sidebar_Click = 'Home_Sidebar_Click';
export const Home_Sidebar_Click_Position = {
  Games: {
    sidebar: 'Games',
    event_label: 'Click Sidebar Popular Games Button',
  },
  Agent: { sidebar: 'Agent', event_label: 'Click Sidebar Agent Button' },
  VIP: { sidebar: 'VIP', event_label: 'Click Sidebar VIP Button' },
  Deposit: { sidebar: 'Deposit', event_label: 'Click Sidebar Deposit Button' },
  Activity: {
    sidebar: 'Activity',
    event_label: 'Click Sidebar Activity Button',
  },
  Cashback: {
    sidebar: 'Cashback',
    event_label: 'Click Sidebar Cashback Button',
  },
  RedPacket: {
    sidebar: 'RedPacket',
    event_label: 'Click Sidebar RedPacket Button',
  },
  PromoCode: {
    sidebar: 'PromoCode',
    event_label: 'Click Sidebar Promo Code Claim Button',
  },
  AboutUs: {
    sidebar: 'AboutUs',
    event_label: 'Click Sidebar About Us Button',
  },
  PWA: { sidebar: 'PWA', event_label: 'Click Sidebar PWA Button' },
  SharePlatform: (name: string) => {
    return { sidebar: name, event_label: `Click Sidebar ${name} Button` };
  },
};

export const Footerbar_Click = 'Footerbar_Click';
export const Footerbar_Click_Position = {
  Tabbar: (name: string) => {
    return { footerbar: name, event_label: `Click Footerbar ${name} Button` };
  },
};

export const User_registration = 'User_registration';
export const User_deposit = 'User_deposit';
export const User_withdrawal = 'User_withdrawal';

export const Home_Popup_Click = 'Home_Popup_Click';
export const Home_Popup_Click_Position = {
  FirstDepositPopup: {
    Popup: 'FirstDepositButton',
    event_label: 'Click Button To Deposit',
  },
  RedPacketGrab: {
    Popup: 'RedPacketGrab',
    event_label: 'Click Button To Grab RedPacket',
  },
};

export const Home_Popup_View = 'Home_Popup_View';
export const Home_Popup_View_Position = {
  FirstDeposit: {
    Popup: 'FirstDeposit',
    event_label: 'View Popup First Deposit',
  },
};

export const Agent_Click = 'Agent_Click';
export const Agent_Click_Position = {
  AreaClick: (area: string) => {
    return { area, event_label: '-' };
  },
};
